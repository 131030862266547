import {ProductsTypeUrlEnum} from "../app/models/enums/enums";

const portalGwoUrl = 'https://gwo.pl/';
const bookshopUrl = 'https://ksiegarnia.gwotest.pl/';

export const environment = {
    domainName: '.gwotest.pl',
    production: false,
    useSentry: true,
    sentryUrl: "https://f11879c6ec59409ebad0cf4c0b7662fe@sentry.gwo.pl/77",
    googleAnalyticsId: undefined,
    cookieName: 'X-Authorization',
    apiUrl: 'https://moje.gwotest.pl/api/v3',
    bookShopApiUrl: `${bookshopUrl}bff-api/v2/`,
    loginGwoUrl: 'https://moje.gwotest.pl/uzytkownik/',
    logOutGwoUrl: 'https://moje.gwotest.pl/uzytkownik/wyloguj',
    portalGwoUrl: portalGwoUrl,
    studentZoneUrl: `${portalGwoUrl}strefa-ucznia`,
    rodoGwoUrl: `${portalGwoUrl}rodo/`,
    featureLinks: {
        akademia: 'https://akademia.gwo.pl/',
        blogGwo: 'https://blog.gwo.pl/',
        labyrinth: 'https://labiryntywiedzy.gwo.pl/',
        eGwo: 'http://e-gwo.pl/',
    },
    aboutLinks: {
        history: `${portalGwoUrl}historia-gwo`,
        kodex: `${portalGwoUrl}kodeks-dobrych-praktyk`,
        patrons: `${portalGwoUrl}pod-patronatem`,
        writeAbout: `${portalGwoUrl}napisali-o-nas`,
        projectsEU: `${portalGwoUrl}projekty-ue`,
        regulations: `${portalGwoUrl}regulaminy`,
    },
    cooperation: {
        beAuthor: `${portalGwoUrl}zostan-autorem`,
        work: `${portalGwoUrl}praca`,
        hurt: `https://hurt.gwo.pl`,
    },
    socials: {
        fbGwo: 'https://pl-pl.facebook.com/GdanskieWydawnictwoOswiatowe',
        fbMatlandia: 'https://www.facebook.com/Matlandia',
    },
    contacts: {
        getContact: `${portalGwoUrl}skontaktuj-sie-z-nami`,
        promotions: `${portalGwoUrl}przedstawiciele-regionalni`,
        help: `${portalGwoUrl}pomoc`,
    },
    chanels: {
        bookshop: bookshopUrl,
        grant: 'https://dotacja.gwo.pl/',
        ls: 'https://ls.gwo.pl/',
        packet: 'https://pakietyszkolne.gwo.pl',
        enlarge: 'https://powiekszamy.gwo.pl',
        akademy: 'https://akademia.gwo.pl/',
    },
    lsChanels: {
        login: `https://ls.gwo.pl`,
        teacher: `https://ls.gwo.pl/oferta-dla-nauczycieli-m104`,
        schools: 'https://ls.gwo.pl/oferta-dla-szkol-m91'
    },
    clubs: {
        formLink: `${portalGwoUrl}formularze`,
    },
    offerLink: {
        [ProductsTypeUrlEnum.MHB]: `${bookshopUrl}?attributes%5Battribute_product_type%5D%5B%5D=multipodręczniki`,
        [ProductsTypeUrlEnum.VIDEO]: `${bookshopUrl}?attributes%5Battribute_publication_type%5D%5B%5D=wykłady_online`,
        [ProductsTypeUrlEnum.EBOOK]: `${bookshopUrl}?attributes%5Battribute_product_type%5D%5B%5D=e_booki`,
        [ProductsTypeUrlEnum.APP]: `${bookshopUrl}?attributes%5Battribute_product_type%5D%5B%5D=multimedia`,
    },
    aiChatSettings: {
        chatLink: 'https://dante-ai.com/embed',
        chatId: '698a0514-01dc-4b98-b178-e2f1653629bf',
        chatToken: 'bc6506d4-3902-41e0-be05-eaa049f54dcf',
        chatModelType: 'gpt-4-omnimodel-mini',
        chatMode: 'false',
        chatLogo: 'dHJ1ZQ%3D%3D',
        isActive: true,
    },
    cookies: {
        styleUrl: 'https://zgody.gwotest.pl/style.css',
        scriptUrl: 'https://zgody.gwotest.pl/bundle.mjs',
    },
};
